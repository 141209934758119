import React from "react"

import Layout from "../../components/layout/layout-st"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { graphql } from 'gatsby'

import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Gallery from '@browniebroke/gatsby-image-gallery'


const Photos = ({ data }) => {
  
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)

  const lightboxOptions = {
    // imageLoadErrorMessage: 'Impossible de charger cette image',
    // nextLabel: 'Image suivante',
    // prevLabel: 'Image précédente',
    // zoomInLabel: 'Zoomer',
    // zoomOutLabel: 'Dézoomer',
    // closeLabel: 'Close',
    imagePadding: 0,
  }

return (
    
  <Layout>
    
    <Seo title="Photos" bodyClass='st' />

    
    <div className="column column--30 alignright">
      <div className="sticky">
        <h1 className={style.titleLowercase}>Photos</h1>
        <p>Lights, Camera, Action! The various Styles of Specyal T.</p>
      </div>
    </div>

    <div className="column column--70">

      <Gallery images={images} lightboxOptions={lightboxOptions}/>

 
      </div>

  </Layout>
)
}

export const query = graphql`
query ImagesForGallery {
  allFile(filter: {relativeDirectory: {eq: "st-gallery"}}) {
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}


`


export default Photos
